import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="copyright">
                <li>&copy; 2024 <a href="http://homeschoolsd.org" target="_blank">Homeschool South Dakota</a></li>
                <li>Design: <a href="https://html5up.net" target="_blank">HTML5 UP</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
